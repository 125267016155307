import { Container, Col, Row } from "react-bootstrap";
import React from "react";
import ExternalLink from "../../components/ExternalLink";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Banner from "../../components/banner";
import Img from "gatsby-image";
import BirthworkImg from "../../assets/images/BirthWork.jpg";

const Birthwork = () => {
  return (
    <>
      <Layout page='article'>
        <SEO title='BirthWork' description='Thriving and Striving for Better Birth Outcomes' image='../images/birthwork.jpg' keywords={[``]} />
        <Banner title='Podcasts' subTitle={``} titleColor='#FFFFFF' subTitleColor='#FFFFFF' hasArrow={true} reverse={true} />
        <section className='center-flex justify-center'>
          <Container className='post-container'>
            <Row>
              <Col className='post' lg='12'>
                <h1><em>BirthWork</em>: Thriving and Striving for Better Birth Outcomes</h1>
                <hr className='horizontalRule dark' />
                <div className='post-content'>
                  <p>
                    Welcome to{" "}
                    <ExternalLink href='https://birthwork.buzzsprout.com/share'>
                      <a><em>BirthWork</em></a>
                    </ExternalLink>{" "}
                    Podcast! Hosted by the Association of Maternal & Child Health Programs’ Women’s and Infant Health Team, <em>BirthWork</em> is your gateway to celebrating the continuous achievements of communities, professionals, practitioners, and families in the journey to create safer and more equitable birthing experiences. In its inaugural season, <em>BirthWork</em> spotlights the grantees of the Safer Childbirth Cities Initiative. These dedicated leaders are tirelessly working to improve maternal health outcomes by meeting the diverse needs of their communities. Tune into the first episode to hear their stories {" "}
                    <ExternalLink href='https://open.spotify.com/show/6nZjeaHVBncNIqL2Oyuay7?si=84b8dee77f574090&nd=1&dlsi=4c90fbd454294767'>
                      <a>here</a>
                    </ExternalLink>
                    !
                  </p>
                  <p>Listen to <em>BirthWork</em>:</p>
                  <ul>
                    <li>
                      <ExternalLink href='https://birthwork.buzzsprout.com/share'>
                        <a>Buzzsprout</a>
                      </ExternalLink>
                    </li>
                    <li>
                      <ExternalLink href='https://open.spotify.com/show/6nZjeaHVBncNIqL2Oyuay7?si=84b8dee77f574090&nd=1&dlsi=f5374f95b8b64ee6'>
                        <a>Spotify</a>
                      </ExternalLink>
                    </li>
                    <li>
                      {" "}
                      <ExternalLink href='https://music.amazon.com/podcasts/46cea236-599c-4f4a-9075-7dd4d2b16129/birthwork'>
                        <a>Amazon Music</a>
                      </ExternalLink>
                    </li>
                  </ul>
                  <h4>Episode 1: Unlocking the Power of MMRCs</h4>
                  <p>
                  In the premiere episode, <em>BirthWork</em> explores the crucial roles of Maternal Mortality Review Committees (MMRCs) in reshaping maternal health outcomes in the United States. Join them as they delve into how MMRCs offer invaluable insights and recommendations to combat maternal mortality in the U.S.
                  </p>
                  <p>Featuring:</p>
                  <ul>
                    <li>Dr. Aasta Mehta, OB/GYN, and Medical Officer of Women’s Health at the City of Philadelphia, Department of Public Health.</li>
                    <li>Teneele Bruce, biologist, doula, and Pathways Project Director at Baltimore Healthy Start.</li>
                  </ul>
                  <h4>Episode 2: What Makes A Doula, Featuring Marna Armstead</h4>
                  <p>In this special two-part episode, your AMCHP hosts speak with two doulas to explore their inspirations and motivations for birth work. Marna Armstead from San Francisco reflects on her experiences in a system that has medicalized birth and what it truly means to advocate as a birth companion through culturally congruent care. Listen to learn about the joy doulas spread to individuals, families, and communities at large helping to create more respectful and equitable birthing spaces from coast to coast. And don't forget to catch part 2 of this episode where we talk to Ankita Patel from Tampa, FL.</p>
                  <p>Featuring:</p>
                  <ul>
                    <li>Marna Armstead, Doula and Co-Founder of SisterWeb in San Francisco, California</li>
                  </ul>
                  <h4>Episode 3: Supporting The Doula, Featuring Ankita Patel</h4>
                  <p>In this special two-part episode, your AMCHP hosts explore what it means to provide doula care to support a diverse community and the fluidity in the role of a doula with Ankita Patel of REACHUP, Inc., a community-based organization in Tampa, FL. Tune in to this episode to garner a new take on how “it takes a village” to equitably bring life into the world. And if you haven’t heard what makes a doula, listen to the part 1 of this episode where we talk to Marna Armstead from San Francisco.</p>
                  <p>Featuring:</p>
                  <ul>
                    <li>Ankita Patel, Doula Program Manager at REACHUP, Inc. in Tampa, Florida</li>
                  </ul>
                </div>
                <Row>
                  <Col lg='4'>
                    <img src={BirthworkImg} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className='arrow-down reverse centered'></div>
        </section>
        <section className='darkBlueColorBkgd'>
          <Container>
            <Row>
              <Col xs='12' sm='12' md='12' lg='12'>
                <div className='spacer60'></div>
                <h2 className='whiteColor'>About Safer Childbirth Cities</h2>
                <p className='whiteColor'>
                  The Safer Childbirth Cities initiative aims to support community-based organizations in U.S. cities with a high burden of maternal
                  mortality and morbidity to implement evidence-based interventions and innovative approaches to reverse the country’s maternal health
                  trends and directly tackle racial inequities in maternal health outcomes. Our vision is for cities to become safer &mdash; and more
                  equitable &mdash; places to give birth.
                </p>
                <div className='spacer60'></div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default Birthwork;
